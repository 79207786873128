import React, {useState} from "react";
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import H5 from '@material-tailwind/react/Heading5';
import InputIcon from '@material-tailwind/react/InputIcon';
import Checkbox from '@material-tailwind/react/Checkbox';
import Button from '@material-tailwind/react/Button';
import DefaultNavbar from 'components/DefaultNavbar';
import SimpleFooter from 'components/SimpleFooter';
import Page from 'components/login/Page';
import Container from 'components/login/Container';

export default function Login() {
    const [email, setEmail] = useState("");
    const [isRemembered, setIsRemembered] = useState(false);

    const userSignIn = () => {
        localStorage.setItem('email', email);
        localStorage.setItem('isRemembered', isRemembered);
        window.location.href = "/";
    };

    return (
        <Page>
            <DefaultNavbar />
            <Container>
                <Card>
                    <CardHeader color="lightBlue">
                        <H5 color="white" style={{ marginBottom: 0 }}>
                            Login
                        </H5>
                    </CardHeader>

                    <CardBody>
                        <div className="mb-12 px-4 bg-bb">
                            <InputIcon
                                type="email"
                                onChange={e => setEmail(e.target.value)}
                                color="lightBlue"
                                placeholder="Email Address"
                                iconName="email"
                            />
                        </div>
                        <div className="mb-8 px-4">
                            <InputIcon
                                type="password"
                                color="lightBlue"
                                placeholder="Password"
                                iconName="lock"
                            />
                        </div>
                        <div className="mb-4 px-4">
                            <Checkbox
                                color="lightBlue"
                                text="Remember Me"
                                id="remember"
                                onChange={e => setIsRemembered(e.target.checked)}
                            />
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="flex justify-between bg-bb">
                            <Button
                                color="lightBlue"
                                buttonType="link"
                                size="regular"
                                ripple="dark"
                                onClick={() => window.location.href = "/register"}
                            >
                                Create Account
                            </Button>
                            <Button
                                color="lightBlue"
                                buttonType="filled"
                                size="regular"
                                ripple="dark"
                                onClick={userSignIn}
                            >
                                Get Started
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
            <SimpleFooter />
        </Page>
    );
}
