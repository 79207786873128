import React, {useState, useEffect} from "react";
import { Switch, Route, Redirect } from 'react-router-dom'
import Sidebar from 'components/Sidebar'
import Dashboard from 'pages/Dashboard'
import Settings from 'pages/Settings'
import Projects from 'pages/Projects'
import NewProject from 'pages/Projects/New'
import Users from 'pages/Users'
import NewUser from 'pages/Users/New'
import Educations from 'pages/Educations'
import NewEducation from 'pages/Educations/New'
import Experiences from 'pages/Experiences'
import NewExperience from 'pages/Experiences/New'
import Footer from 'components/Footer'
import Profile from 'pages/Profile'
import Login from 'pages/Login'
import Register from 'pages/Register'
import ScrollToTop from './ScrollToTop';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css'

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css'

function App() {
    const [loading, setLoading] = useState(true);
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    useEffect(() => {
        const email = localStorage.getItem('email');
        const isRemembered = localStorage.getItem('isRemembered');
        console.log(email, isRemembered, email && isRemembered)
        if(email && isRemembered) setUserLoggedIn(true);
        setLoading(false)
    }, [])

    if(loading)
        return <h1>Loading</h1>;

    return (
        <ScrollToTop>
            <Switch>
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/register" component={Register} />
                {userLoggedIn ? 
                    <>
                        <Sidebar />
                        <div className="md:ml-64">
                            <Route exact path="/" component={Dashboard} />
                            <Route exact path="/users" component={Users} />
                            <Route exact path="/user/new" component={NewUser} />
                            <Route exact path="/projects" component={Projects} />
                            <Route exact path="/project/new" component={NewProject} />
                            <Route exact path="/educations" component={Educations} />
                            <Route exact path="/education/new" component={NewEducation} />
                            <Route exact path="/experiences" component={Experiences} />
                            <Route exact path="/experience/new" component={NewExperience} />
                            <Route exact path="/settings" component={Settings} />
                            <Redirect from="*" to="/" />
                            <Footer />
                        </div>
                    </> :
                    <>
                        <Route exact path="/" component={Login} />
                        <Redirect from="*" to="/" />
                    </>
                }
            </Switch>
        </ScrollToTop>
    )
}

export default App
